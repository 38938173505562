import {createTransform} from "redux-persist";

const AuthTransformer = createTransform(
    (subState: any) => {
        return { ...subState };
    },
    (state) => {
        if (state.expiry) {
            let expiryTime = new Date(state.expiry);
            if (expiryTime.getTime() < (new Date()).getTime()) {
                state = { user: undefined, expiry: undefined };
            }
        }
        return { ...state }
    },
    { whitelist: ['user'] }
);

export default AuthTransformer;
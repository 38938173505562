import BasePage from "../../layout/BasePage";
import {FormEvent, useState} from "react";
import ApiHelper from "../../app/helpers/ApiHelper";
import {useAppSelector} from "../../app/redux/hooks";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {CaseCreateRequest} from "../../app/models/cases/CaseCreateRequest";
import CaseHelper from "../../app/helpers/CaseHelper";

export default function CreateCase() {
    return(
        <BasePage pageName={"Create case"} content={
            <CreateCaseForm />
        } />
    );
}

function CreateCaseForm() {
    const user = useAppSelector((state => state.user.user));
    const [title, setTitle] = useState("");
    const [contactName, setContactName] = useState("");
    const [contactPhone, setContactPhone] = useState("");
    const [contactEmail, setContactEmail] = useState("");
    const [externalDocNo, setExternalDocNo] = useState("");
    const [comment, setComment] = useState("");
    const [isCreatingCase, setIsCreatingCase] = useState(false);
    const navigate = useNavigate();

    const createCase = async (event: FormEvent) => {
        event.preventDefault();

        if (!user)
            return;

        setIsCreatingCase(true);

        let caseBody: CaseCreateRequest = {
            description: title,
            contactName: contactName,
            contactPhone: contactPhone,
            contactEmail: contactEmail,
            externalDocNo: externalDocNo,
            comment: comment
        }

        if (!CaseHelper.caseIsValid(caseBody)) {
            toast.error("The case data is not valid for creating a case. Please double check the case and try submitting again.");
            setIsCreatingCase(false);
            return;
        }

        let response: any;
        try {
            response = await ApiHelper.makePostRequest("cases", JSON.stringify(caseBody), {
                'Authorization': 'Bearer ' + user.accessToken,
                'Content-Type': 'application/json'
            });

            if (!response.ok) {
                ApiHelper.apiError(response);
            }

            response = await response.json();
        } catch (e) {
            console.error(e);
            toast.error("An error occurred while submitting the case. Please try again in a while");
            setIsCreatingCase(false);
            return;
        }

        let caseNo = response.case.caseNo;
        toast.success("Successfully submitted case!");
        setIsCreatingCase(false);
        navigate("/case/" + caseNo);
    }

    return (
        <div className={"flex"}>
            <div className={"w-1/2"} style={{maxWidth: '600px'}}>
                <form className="space-y-2" onSubmit={(e) => createCase(e)}>
                    <div className="space-y-3">
                        <div>
                            <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                                Title
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="title"
                                    id="title"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 outline-none block w-full sm:text-sm border border-gray-200 rounded-md p-2"
                                    onChange={(e) => setTitle(e.currentTarget.value)}
                                    maxLength={50}
                                    required={true}
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="contactName" className="block text-sm font-medium text-gray-700">
                                Contact Name
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="contactName"
                                    id="contactName"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 outline-none block w-full sm:text-sm border border-gray-200 rounded-md p-2"
                                    onChange={(e) => setContactName(e.currentTarget.value)}
                                    required={true}
                                    maxLength={30}
                                    placeholder={"John Doe"}
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="contactPhone" className="block text-sm font-medium text-gray-700">
                                Contact Phone Number
                            </label>
                            <div className="mt-1">
                                <input
                                    type="tel"
                                    name="contactPhone"
                                    id="contactPhone"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 outline-none block w-full sm:text-sm border border-gray-200 rounded-md p-2"
                                    onChange={(e) => setContactPhone(e.currentTarget.value)}
                                    required={true}
                                    maxLength={30}
                                    placeholder={"+45 12345678"}
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="contactEmail" className="block text-sm font-medium text-gray-700">
                                Contact E-mail
                            </label>
                            <div className="mt-1">
                                <input
                                    type="email"
                                    name="contactEmail"
                                    id="contactEmail"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 outline-none block w-full sm:text-sm border border-gray-200 rounded-md p-2"
                                    onChange={(e) => setContactEmail(e.currentTarget.value)}
                                    required={true}
                                    maxLength={80}
                                    placeholder={"example@example.com"}
                                />
                            </div>
                        </div>
                        <div>
                            <div className={"flex justify-between"}>
                                <label htmlFor="externalDocNo" className="block text-sm font-medium text-gray-700">
                                    External Document No.
                                </label>
                                <span className="text-sm text-gray-500">
                                    Optional
                                </span>
                            </div>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="externalDocNo"
                                    id="externalDocNo"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 outline-none block w-full sm:text-sm border border-gray-200 rounded-md p-2 uppercase"
                                    onChange={(e) => setExternalDocNo(e.currentTarget.value)}
                                    maxLength={20}
                                    required={false}
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="comment" className="block text-sm font-medium text-gray-700">
                                Description of case
                            </label>
                            <div className="mt-1">
                                <textarea
                                    rows={4}
                                    name="comment"
                                    id="comment"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 rounded-md p-2 block w-full sm:text-sm outline-none border border-gray-200"
                                    defaultValue={''}
                                    onChange={(e) => {setComment(e.currentTarget.value)}}
                                    required={true}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="pt-5">
                        <div className="flex">
                            <button
                                type="submit"
                                className={ isCreatingCase
                                    ? "bg-gray-100 px-3 py-2 border border-gray-300 rounded text-white"
                                    : "bg-blue-600 px-3 py-2 border border-blue-600 rounded text-white"
                                }
                                disabled={isCreatingCase}
                            >
                                Create case
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div className={"w-1/2 ml-8"}>
                <h3 className={"mb-4"}>Notes on creating cases</h3>
                <p>
                    At NaviPartner we want to assist you as fast as possible. <br />
                    If you are creating a case for an incident, please provide the following information for us to be able
                    to assist you as quickly as possible: <br /> <br />
                    <ul className={"list-disc pl-4"}>
                        <li>A short, concise title</li>
                        <li>Contact information</li>
                        <li>The desired result</li>
                        <li>The actual result</li>
                        <li>When and where the error happened, e.g. "28th of April at 15:35 in company "Example Company"</li>
                        <li>Steps for us to reproduce the error</li>
                    </ul><br />
                    Thank you in advance!
                    <br />
                    <br />
                    If the incident is caused by failed/unintended setup or use of the system by the user/customer, NaviPartner's time spent could be invoiceable.
                </p>
            </div>
        </div>
    );
}
import {useMsal} from "@azure/msal-react";
import {AccountInfo, AuthenticationResult} from "@azure/msal-browser";
import {b2cPolicies} from "./authConfig";
import {User} from "../models/users/User";
import {useAppSelector} from "../redux/hooks";
import {useNavigate} from "react-router-dom";
import {useAuth} from "./AuthProvider";
import {useEffect} from "react";

export default function AuthHandler() {
    const { instance } = useMsal();
    const auth = useAuth();
    const navigate = useNavigate();
    const user = useAppSelector((state => state.user.user));

    let localAccountId = "";
    let accessToken = "";

    useEffect(() => {
        instance.handleRedirectPromise()
            .then((response) => {
                if (response) {
                    // @ts-ignore
                    if (response.idTokenClaims['tfp'].toUpperCase() === b2cPolicies.names.signUpSignIn.toUpperCase() ||
                        // @ts-ignore
                        response.idTokenClaims['tfp'].toUpperCase() === b2cPolicies.names.signUp.toUpperCase())
                    {
                        if (user === undefined || user.b2cAccountId !== response.account?.localAccountId) {
                            accessToken = response.idToken;
                            handleResponse(response);
                        }

                        if (user) navigate('/auth/handle-login', {replace: true});
                    }
                } else {
                    navigate('/', {replace: true});
                }
            })
            .catch((error) => {
                console.error(error);
                navigate('/', {replace: true});
            });
    }, []);

    function setAccount(account: AccountInfo, expiresOn: Date) {
        localAccountId = account.localAccountId;
        auth.signin({b2cAccountId: localAccountId, accessToken: accessToken, name: (account.name ? account.name : "")}, expiresOn ?? new Date(), () => {
            console.log("Account set! ID: " + localAccountId);
            navigate("/auth/handle-login", { replace: true });
        });
    }

    function handleResponse(response: AuthenticationResult) {
        if (response != null && response.account != null) {
            // @ts-ignore
            setAccount(response.account, new Date(response.idTokenClaims.exp * 1000));
        }
    }

    return (
        <></>
    );
}
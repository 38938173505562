import {Case} from "../../app/models/cases/Case";
import CaseStatus from "../../app/models/cases/CaseStatus";
import StringUtils from "../../app/helpers/StringUtils";
import {addComment, CaseCommentState} from "../../app/redux/cases/caseCommentsSlice";
import React, {FormEvent, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../app/redux/hooks";
import ApiHelper from "../../app/helpers/ApiHelper";
import {fetchCaseByNo} from "../../app/redux/cases/casesSlice";
import {addCaseFile, CaseFileState} from "../../app/redux/cases/caseFilesSlice";
import {toast} from "react-toastify";

type CaseCardProps = {
    case: Case,
    commentState: CaseCommentState,
    fileState: CaseFileState
}

export default function CaseCard(props: CaseCardProps) {
    const user = useAppSelector(state => state.user.user);
    const caseCommentState = useAppSelector(state => state.caseCommentState);
    const dispatch = useAppDispatch();
    const [userComment, setUserComment] = useState("");
    const [contactName, setContactName] = useState("");
    const [contactEmail, setContactEmail] = useState("");
    const [contactPhone, setContactPhone] = useState("");
    const [externalDocNo, setExternalDocNo] = useState("");
    const [dragging, setDragging] = useState(false);
    const [isUpdatingCase, setIsUpdatingCase] = useState(false);

    const addCommentFormSubmit = (event: FormEvent) => {
        event.preventDefault();

        if (!(props.case && user))
            return;

        dispatch(addComment({
            user: user,
            caseNo: props.case.No,
            comment: userComment
        }));

        setUserComment("");
    }

    const updateCaseDetails = async (event: FormEvent) => {
        event.preventDefault();

        if (!(props.case && user))
            return;

        setIsUpdatingCase(true);

        let eTagHeader = "W/\"'" + props.case.ETag + "'\"";

        let body = {
            contactName: contactName,
            contactEmail: contactEmail,
            contactPhone: contactPhone,
            externalDocNo: externalDocNo
        }

        try {
            let response = await ApiHelper.makePatchRequest('cases/' + props.case.No, JSON.stringify(body), {
                'If-Match': eTagHeader,
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.accessToken
            });

            if (!response.ok) {
                ApiHelper.apiError(response);
            }

            dispatch(fetchCaseByNo({user: user, caseNo: props.case.No}));
        } catch (e) {
            console.error(e);
            toast.error("An error occurred while trying to update the case details. Please wait a while and try again");
            setIsUpdatingCase(false);
            return;
        }

        setIsUpdatingCase(false);
    }

    const completeCase = async () => {
        if (!(props.case && user))
            return;

        setIsUpdatingCase(true);

        let eTagHeader = "W/\"'" + props.case.ETag + "'\"";

        try {
            let response = await ApiHelper.makePostRequestWithoutBody('cases/' + props.case.No + '/complete', {
                'If-Match': eTagHeader,
                'Authorization': 'Bearer ' + user.accessToken
            });

            if (!response.ok) {
                ApiHelper.apiError(response);
            }

            dispatch(fetchCaseByNo({user: user, caseNo: props.case.No}));
        } catch (e) {
            console.error(e);
            toast.error("An error occurred while completing the case. Please wait a while and try again");
            setIsUpdatingCase(false);
            return;
        }

        setIsUpdatingCase(false);
    }

    const downloadFile = async (fileId: string, fileName: string) => {
        if (!user)
            return;

        let response: any;
        try {
            response = await ApiHelper.makeGetRequest("cases/" + props.case.No + "/files/" + fileId, {
                'Authorization': 'Bearer ' + user.accessToken
            });

            if (!response.ok) {
                ApiHelper.apiError(response);
            }
        } catch (e) {
            console.error(e);
            toast.error("An error occurred while downloading the file. Please wait a while and try again");
            return;
        }

        response = await response.json();

        if (!response.base64) {
            console.error("File response was malformed.\nBody: " + JSON.stringify(response));
            toast.error("File response was malformed");
            return;
        }

        const source = 'data:application/octet-stream;base64,' + response.base64; // application/octet-stream might not work all scenarios?
        const downloadLink = document.createElement("a");
        downloadLink.href = source;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    const uploadFile = async (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (!(props.case && user))
            return;

        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {

            for (let i = 0; i < event.dataTransfer.files.length; i++) {
                let file = event.dataTransfer.files.item(i);
                if (file && (file.size > 2000000)) { // 2MB
                    console.log(file.size);
                    toast.error("File is too big to upload!");
                }
            }

            dispatch(addCaseFile({user: user, caseNo: props.case.No, files: event.dataTransfer.files}));
        }

        setDragging(false);
    }

    const onDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragging(true);
    }

    const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragging(false);
    }

    useEffect(() => {
        setContactName(props.case.contactName);
        setContactEmail(props.case.contactEmail);
        setContactPhone(props.case.contactPhone);
        setExternalDocNo(props.case.externalDocNo);
    }, [props.case]);

    return(
        <div className={"case-card-container max-w-full"}>
            <div className={"flex"}>
                <div className={"w-3/12 overflow-y-auto mr-2"}>
                    <h2 className={"text-xl font-semibold text-gray-700"}>Case information</h2>
                    <form onSubmit={(e) => updateCaseDetails(e)}>
                        <div className={"flex mt-2"}>
                            <InputField style={"w-1/2 mr-2"} type={"text"} name={"caseNo"} label={"Case No."} placeholder={""} value={props.case.No} disabled={true} />
                            <InputField style={"w-1/2 ml-2"} type={"text"} name={"status"} label={"Status"} placeholder={""} value={StringUtils.capitalize(CaseStatus[props.case.status] ?? "")} disabled={true} />
                        </div>
                        <div className={"flex mt-2"}>
                            <InputField style={"w-1/2 mr-2"} type={"text"} name={"responsibleName"} label={"Responsible"} placeholder={""} value={props.case.personResponsibleName} disabled={true} />
                            <InputField style={"w-1/2 ml-2"} type={"text"} name={"createBy"} label={"Created By"} placeholder={""} value={props.case.createdBy} disabled={true} />
                        </div>
                        <div className={"mt-2"}>
                            <InputField type={"text"} name={"projectCode"} label={"Project Code"} placeholder={""} value={props.case.projectCode} disabled={true} />
                        </div>
                        <div className={"flex mt-2"}>
                            <InputField style={"w-1/2 mr-2"} type={"text"} name={"dateCreated"} label={"Created"} placeholder={""} value={StringUtils.getDateFormat(props.case.creationDate)} disabled={true} />
                            <InputField style={"w-1/2 ml-2"} type={"text"} name={"dateModified"} label={"Last Modified"} placeholder={""} value={StringUtils.getDateFormat(props.case.lastModifiedDate)} disabled={true} />
                        </div>
                        <div className={"flex mt-2"}>
                            <InputField style={"w-1/2 mr-2"} type={"number"} name={"estimatedHrs"} label={"Estimated Hours"} placeholder={""} value={props.case.estimatedHrs} disabled={true} />
                            <InputField style={"w-1/2 mæ-2"} type={"number"} name={"estimatedHrsLeft"} label={"Estimated Hours Left"} placeholder={""} value={props.case.estimatedHrsLeft} disabled={true} />
                        </div>
                        {
                            props.case.settledPrice.toString() !== "0"
                                ?   <div className={"mt-2"}>
                                        <InputField type={"number"} name={"settledPrice"} label={"Settled Price (hours)"} placeholder={""} value={props.case.settledPrice} disabled={true} />
                                    </div>
                                : <></>
                        }
                        <div className={"mt-2"}>
                            <div className={"mt-2"}>
                                <label htmlFor={"externalDocNo"} className="block text-sm font-medium text-gray-700">
                                    { "External Document No." }
                                </label>
                                <div className="mt-1">
                                    <input
                                        type={"text"}
                                        name={"externalDocNo"}
                                        id={"externalDocNo"}
                                        className="focus:ring-indigo-500 focus:border-indigo-500 outline-none block w-full sm:text-sm border border-gray-200 rounded-md p-2 uppercase"
                                        defaultValue={props.case.externalDocNo}
                                        onChange={(e) => setExternalDocNo(e.currentTarget.value)}
                                        maxLength={20}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={"mt-2"}>
                            <div className={"mt-2"}>
                                <label htmlFor={"attName"} className="block text-sm font-medium text-gray-700">
                                    { "Customer Contact Name" }
                                </label>
                                <div className="mt-1">
                                    <input
                                        type={"text"}
                                        name={"attName"}
                                        id={"attName"}
                                        className="focus:ring-indigo-500 focus:border-indigo-500 outline-none block w-full sm:text-sm border border-gray-200 rounded-md p-2"
                                        defaultValue={props.case.contactName}
                                        onChange={(e) => setContactName(e.currentTarget.value)}
                                        maxLength={30}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={"mt-2"}>
                            <div className={"mt-2"}>
                                <label htmlFor={"attPhone"} className="block text-sm font-medium text-gray-700">
                                    { "Customer Contact Phone No." }
                                </label>
                                <div className="mt-1">
                                    <input
                                        type={"tel"}
                                        name={"attPhone"}
                                        id={"attPhone"}
                                        className="focus:ring-indigo-500 focus:border-indigo-500 outline-none block w-full sm:text-sm border border-gray-200 rounded-md p-2"
                                        defaultValue={props.case.contactPhone}
                                        onChange={(e) => setContactPhone(e.currentTarget.value)}
                                        maxLength={30}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={"mt-2"}>
                            <div className={"mt-2"}>
                                <label htmlFor={"attEmail"} className="block text-sm font-medium text-gray-700">
                                    { "Customer Contact E-mail" }
                                </label>
                                <div className="mt-1">
                                    <input
                                        type={"email"}
                                        name={"attEmail"}
                                        id={"attEmail"}
                                        className="focus:ring-indigo-500 focus:border-indigo-500 outline-none block w-full sm:text-sm border border-gray-200 rounded-md p-2"
                                        defaultValue={props.case.contactEmail}
                                        onChange={(e) => setContactEmail(e.currentTarget.value)}
                                        maxLength={80}
                                    />
                                </div>
                            </div>
                        </div>
                        {
                            !([CaseStatus.completed, CaseStatus.closed].includes(props.case.status)) // Case is not already completed or closed
                                ?   <div className={"mt-4"}>
                                        <button type={"submit"}
                                                className={
                                                    isUpdatingCase
                                                        ? "bg-gray-100 px-3 py-2 border border-gray-300 rounded text-sm text-white"
                                                        : "bg-blue-600 px-3 py-2 border border-blue-600 rounded text-sm text-white"
                                                }
                                                disabled={isUpdatingCase}
                                        >
                                            Update case details
                                        </button>
                                        <button type={"button"}
                                                className={
                                                    isUpdatingCase
                                                        ? "ml-2 px-3 py-2 border border-gray-300 text-gray-300 rounded text-sm"
                                                        : "ml-2 px-3 py-2 border border-blue-600 text-blue-600 rounded text-sm"
                                                }
                                                onClick={(e) => completeCase()}
                                                disabled={isUpdatingCase}
                                        >
                                            Complete case
                                        </button>
                                    </div>
                                : <></>
                        }
                    </form>
                </div>
                <div className={"w-7/12 mx-2 flex-1"}>
                    <h2 className={"text-xl font-semibold text-gray-700"}>Case comments</h2>
                    <div className={"flex flex-col"}>
                    {
                        props.commentState.isLoading
                            ? <p>Loading comments...</p>
                            : <div>
                                <div className="mt-2 overflow-y-auto" style={{maxHeight: 'calc(100vh - 400px)'}}>
                                    {
                                        Object.values(props.commentState.caseComments).map((comment) => (
                                            <div key={comment.Linienr} className={"flex"}>
                                                {/*<div className="py-2 mr-4 w-1/12 text-sm text-gray-500">{StringUtils.getDateFormat(comment.date)}</div>*/}
                                                <div className="py-2 w-3/12 text-sm text-gray-500">{comment.name}</div>
                                                <div className="py-2 w-9/12 text-sm text-gray-500">{comment.comment}</div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className={"mt-4"}>
                                    <form onSubmit={(e) => addCommentFormSubmit(e)}>
                                        <div>
                                            <label htmlFor="comment" className="block text-sm font-medium text-gray-700">
                                                Add your comment
                                            </label>
                                            <div className="mt-1">
                                            <textarea
                                                rows={4}
                                                name="comment"
                                                id="comment"
                                                className="focus:ring-indigo-500 focus:border-indigo-500 rounded-md p-2 block w-full sm:text-sm outline-none border border-gray-200"
                                                defaultValue={''}
                                                onChange={(e) => {setUserComment(e.target.value)}}
                                                value={userComment}
                                            />
                                            </div>
                                        </div>
                                        <div className={"mt-2"}>
                                            <button type={"submit"}
                                                    disabled={caseCommentState.addingComment}
                                                    className={
                                                        caseCommentState.addingComment
                                                            ? "bg-gray-100 px-3 py-2 border border-gray-300 rounded text-white text-sm"
                                                            : "bg-blue-600 px-3 py-2 border border-blue-600 rounded text-white text-sm"}
                                            >
                                                Add comment
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                    }
                    </div>
                </div>
                <div className={"w-2/12 ml-2 flex-1 overflow-hidden"}>
                    <h2 className={"text-xl font-semibold text-gray-700"}>Files</h2>
                    <div className={"flex flex-col"}>
                        <div className={"overflow-y-auto"} style={{maxHeight: 'calc(100vh - 350px)'}}>
                            {
                                Object.values(props.fileState.caseFiles).map((file) => (
                                    <div key={file.ID} className={"flex"}>
                                        <button type={"button"} className="py-2 text-sm text-gray-500" data-fileid={file.ID} data-filename={file.filename} onClick={(e) => {
                                            let fileId = e.currentTarget.dataset.fileid;
                                            let filename = e.currentTarget.dataset.filename;
                                            if (fileId && filename)
                                                downloadFile(fileId, filename);
                                        }}>{file.filename}</button>
                                    </div>
                                ))
                            }
                        </div>
                        <div>
                            <div className={"w-full text-center rounded-md mt-4 " + ((dragging) ? "bg-green-100" : "bg-gray-100")}
                                 style={{height: '100px', lineHeight: '100px'}}
                                 onDragOver={event => onDragEnter(event)}
                                 onDragEnter={event => onDragEnter(event)}
                                 onDragLeave={event => onDragLeave(event)}
                                 onDrop={event => uploadFile(event)}>
                                Drop file here
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function InputField(input: {style?: string; type: string, name: string, label: string, placeholder: string, value: string | number, disabled: boolean}) {
    return (
        <div className={"mt-2" + (input.style) ? " " + input.style : ""}>
            <label htmlFor={input.name} className="block text-sm font-medium text-gray-700">
                { input.label }
            </label>
            <div className="mt-1">
                <input
                    type={input.type}
                    name={input.name}
                    id={input.name}
                    className="focus:ring-indigo-500 focus:border-indigo-500 outline-none block w-full sm:text-sm border border-gray-200 rounded-md p-2"
                    placeholder={input.placeholder}
                    value={input.value}
                    disabled={input.disabled}
                />
            </div>
        </div>
    )
}

import {Case} from "../../app/models/cases/Case";
import CaseStatus from "../../app/models/cases/CaseStatus";
import StringUtils from "../../app/helpers/StringUtils";
import {useAppDispatch, useAppSelector} from "../../app/redux/hooks";
import Contact from "../../app/models/cases/Contact";
import {useNavigate} from "react-router-dom";
import {fetchNextCases} from "../../app/redux/cases/casesSlice";

type CaseListProps = {
    cases: Case[],
    fullyLoaded: boolean,
    isLoading: boolean,
    nextFunction: Function
}

function CaseList(props: CaseListProps) {
    const navigate = useNavigate();

    return (
        <div className={"case-list-container"}>
            <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto">
                    <div className="align-middle inline-block min-w-full">
                        <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Case No.
                                    </th>
                                    <th scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Description
                                    </th>
                                    <th scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Status
                                    </th>
                                    <th scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Contact
                                    </th>
                                    <th scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        NaviPartner Responsible
                                    </th>
                                    <th scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Creation Date
                                    </th>
                                    <th scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Last Modified Date
                                    </th>
                                    <th scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        External Document No.
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {props.cases.map((npCase, caseIndex) => (
                                    <tr key={npCase.No}
                                        className={(caseIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50') + " cursor-pointer"}
                                        onClick={() => navigate("/case/" + npCase.No)}
                                    >
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{npCase.No}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{npCase.description}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{StringUtils.capitalize(CaseStatus[npCase.status])}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{npCase.contactName}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{npCase.personResponsibleName}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{StringUtils.getDateFormat(npCase.creationDate)}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{StringUtils.getDateFormat(npCase.lastModifiedDate)}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{npCase.externalDocNo}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>

                        </div>
                    </div>
                    <div className={"mt-4 flex justify-center"}>
                        {
                            (!props.fullyLoaded)
                                ?   <button type={"button"}
                                            onClick={() => props.nextFunction()}
                                            className={"bg-blue-600 px-4 py-2 border border-blue-600 rounded text-white " + (props.isLoading ? "invisible" : "")}
                                    >
                                        Load more
                                    </button>
                                : (<></>)
                        }
                    </div>
                </div>
            </div>
        </div>
    );

}

export default CaseList;
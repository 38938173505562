import {CaseFile} from "../../models/cases/CaseFile";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {User} from "../../models/users/User";
import ApiHelper from "../../helpers/ApiHelper";
import {toast} from "react-toastify";

export interface CaseFileState {
    isLoading: boolean;
    caseFiles: {
        [fileID: string]: CaseFile
    }
}

const initialState: CaseFileState = { isLoading: false, caseFiles: {}};

const caseFilesSlice = createSlice({
    name: 'caseFiles',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase('caseFiles/fetchCaseFiles/pending', (state) => {
                state.isLoading = true;
                state.caseFiles = {};
            })
            .addCase('caseFiles/fetchCaseFiles/fulfilled', (state, action: any) => {
                state.isLoading = false;

                if (!action.payload) {
                    return;
                }

                action.payload.caseFiles.forEach((file: any) => {
                    file.size = +file.size;
                    state.caseFiles[file.ID] = file as CaseFile;
                });
            })
            .addCase('caseFiles/addCaseFile/fulfilled', (state, action: any) => {
                if (!action.payload) {
                    return;
                }

                let file = action.payload.file;
                file.size = +file.size;
                state.caseFiles[file.ID] = file as CaseFile;
            })
    }
});

export const fetchCaseFiles = createAsyncThunk<{ caseFiles: { [fileId: string]: CaseFile } } | undefined, { user: User, caseNo: string }>('caseFiles/fetchCaseFiles', async (input) => {
    if (!input.caseNo || input.caseNo === "")
        return undefined;

    if (!input.user)
        return undefined;

    let url = 'cases/' + input.caseNo + '/files/';

    let response: any;
    try {
        response = await ApiHelper.makeGetRequest(url, {
            'Authorization': 'Bearer ' + input.user.accessToken,
            'Accept': 'application/json'
        });

        if (!response.ok) {
            ApiHelper.apiError(response);
        }

        response = await response.json();
    } catch (e) {
        console.error(e);
        toast.error("Error while fetching case files. Please wait a while and try again");
        return;
    }

    return { caseFiles: response };
});

export const addCaseFile = createAsyncThunk<{ file: CaseFile } | undefined, { user: User, caseNo: string, files: FileList}>('caseFiles/addCaseFile', async (input) => {
    if (!input.caseNo || input.caseNo === "")
        return undefined;

    if (!input.user)
        return undefined;

    let url = "cases/" + input.caseNo + "/files/";

    for (let i = 0; i < input.files.length; i++) {
        let file = input.files.item(i);

        if (file) {
            let base64: any = await getBase64(file);
            base64 = base64.replace(/^data:.+;base64,/, ""); // Remove metadata

            let body = {
                fileName: file.name,
                base64: base64
            }

            let response: any;
            try {
                response = await ApiHelper.makePostRequest(url, JSON.stringify(body), {
                    'Authorization': 'Bearer ' + input.user.accessToken,
                    'Content-Type': 'application/json'
                });

                if (!response.ok) {
                    ApiHelper.apiError(response);
                }

                response = await response.json();
            } catch (e) {
                console.error(e);
                toast.error("Error while adding file. Please wait a while and try again");
                return;
            }

            return { file: {...response.file, caseNo: input.caseNo} }
        }
    }

    return undefined;
});

function getBase64(file: File) {
    return new Promise(resolve => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onloadend = () => {
            resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
            console.error('Error while base64 encoding file: ' + error);
            toast.error("An error occurred while processing your file");
        }
    });
}

export default caseFilesSlice.reducer;

import {useEffect} from "react";
import {useAuth} from "../../app/auth/AuthProvider";

export default function LogOut() {
    const auth = useAuth();

    useEffect(() => {
        auth.signout();
    })

    return(<></>)
}
export default class StringUtils {
    /**
     * Capitalizes the first letter of a string.
     * <br />
     * If the string is undefined or empty, the method will
     * return the input to the caller.
     *
     * @param input string to be capitalized
     */
    static capitalize(input: string): string {
        if (input === undefined || input.length === 0)
            return input;

        return input[0].toUpperCase() + input.substr(1);
    };

    static getDateFormat(input: Date): string {
        if (input === undefined)
            return "";

        return input.getDate() + "/" + (input.getMonth() + 1) + "-" + input.getFullYear();
    }
}
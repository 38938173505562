import React, {useState} from "react";
import {useAuth} from "../../app/auth/AuthProvider";
import ApiHelper from "../../app/helpers/ApiHelper";
import {useAppDispatch} from "../../app/redux/hooks";
import {useNavigate} from "react-router-dom";

export default function LinkPreviousAccount() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const auth = useAuth();

    const handleDataChange = (event: React.FormEvent<HTMLInputElement>) => {
        switch(event.currentTarget.name) {
            case "username":
                setUsername(event.currentTarget.value);
                break;
            case "password":
                setPassword(event.currentTarget.value);
                break;
        }
    };

    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        let body: any = {
            userID: auth.authUser.b2cAccountId,
            username: username,
            password: password
        }

        let response: any;
        body = JSON.stringify(body);
        try {
            // TODO: Eliminate this fetch to using ApiHelper instead
            response = await fetch(ApiHelper._baseUrl + 'contacts/mapAccount', {
                method: 'POST',
                body: body,
                headers: {
                    ...ApiHelper.getDefaultHeaders(),
                    'Authorization': 'Bearer ' + auth.authUser.accessToken,
                    'Content-Type': 'application/json'
                }
            });
        } catch(e) {
            console.log('Error while registering account: ' + e);
            return;
        }

        if (response.status >= 400) {
            console.log('Error while registering account:\n' +
                'Status Code: ' + response.status +  '\n' +
                'Status Text: ' + response.statusText + '\n' +
                'Body: ' + await response.text()
            );
            return;
        }

        response = await response.json();

        if (response.contactNo && response.contactNo !== "")
            navigate('/cases');
    };

    if (auth.authUser.contactNo)
        navigate('/cases', {replace: true});

    return(
        <div className={"min-h-screen min-w-full flex items-center"}>
            <div className={"mx-auto my-0 min-w-1/4 flex flex-col"}>
                <h1 className="text-2xl font-semibold text-gray-900 text-center mb-4">Link previous account</h1>
                <form className="space-y-6" onSubmit={(e) => handleSubmit(e)}>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Username
                        </label>
                        <div className="mt-1">
                            <input
                                id="username"
                                name="username"
                                type="text"
                                required
                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                onChange={(e) => handleDataChange(e)}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                            Password
                        </label>
                        <div className="mt-1">
                            <input
                                id="password"
                                name="password"
                                type="password"
                                required
                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                onChange={(e) => handleDataChange(e)}
                            />
                        </div>
                    </div>
                    <div>
                        <button
                            type="submit"
                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium bg-blue-600 border border-blue-600 text-white"
                        >
                            Link account
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
import {Action, combineReducers, configureStore, ThunkAction} from '@reduxjs/toolkit';
import casesReducers from "./cases/casesSlice";
import caseFilesReducer from "./cases/caseFilesSlice";
import caseCommentsReducers from "./cases/caseCommentsSlice";
import userReducers from "./users/userSlice";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import storage from "redux-persist/lib/storage";
import AuthTransformer from "./authTransformer";

const persistConfig = {
    key: 'root',
    storage,
    blacklist: [
        'caseState',
        'caseCommentState',
        'caseFileState'
    ],
    transforms: [AuthTransformer]
}

const rootReducer = combineReducers({
    caseState: casesReducers,
    caseCommentState: caseCommentsReducers,
    caseFileState: caseFilesReducer,
    user: userReducers
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
    devTools: (process.env.NODE_ENV !== 'production')
});


export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

import React from 'react';
import './App.css';
import {Navigate, Route, Routes} from "react-router-dom";
import Home from "./components/Home/Home";
import CaseListPage from "./components/Cases/CaseListPage";
import CaseCardPage from "./components/Cases/CaseCardPage";
import NotFound from "./components/NotFound/NotFound";
import {MsalProvider} from "@azure/msal-react";
import {PublicClientApplication} from "@azure/msal-browser/dist/app/PublicClientApplication";
import AuthHandler from "./app/auth/AuthHandler";
import {msalConfig} from "./app/auth/authConfig";
import UserLoadingPage from "./components/Authentication/UserLoadingPage";
import LogOut from "./components/Authentication/LogOut";
import RequireAuth from "./app/auth/RequireAuth";
import AuthProvider from "./app/auth/AuthProvider";
import LinkPreviousAccount from "./components/Authentication/LinkPreviousAccount";
import CreateCase from "./components/Cases/CreateCase";
import TimebankOverview from "./components/Timebank/TimebankOverview";
import StatusScannerOrder from "./components/StatusScanner/StatusScannerOrder";
import PasswordReset from "./app/auth/PasswordReset";

export default function App() {
    const msalInstance = new PublicClientApplication(msalConfig);

    return (
        <MsalProvider instance={msalInstance}>
            <AuthProvider>
                <Routes>
                    <Route path={"cases"}>
                        <Route path={":status"} element={<RequireAuth><CaseListPage /></RequireAuth>} />
                        <Route path={"create"} element={<RequireAuth><CreateCase /></RequireAuth>} />
                        <Route path={""} element={<Navigate to={"active"} replace={true} />} />
                    </Route>
                    <Route path={"case"}>
                        <Route path={":caseNo"} element={<RequireAuth><CaseCardPage /></RequireAuth>} />
                    </Route>
                    <Route path={"auth"}>
                        <Route path={"password/reset"} element={<PasswordReset />} />
                        <Route path={"response"} element={<AuthHandler />} />
                        <Route path={"handle-login"} element={<RequireAuth><UserLoadingPage /></RequireAuth> } />
                        <Route path={"previousAccount"} element={<RequireAuth><LinkPreviousAccount /></RequireAuth>} />
                        <Route path={"logout"} element={<RequireAuth><LogOut /></RequireAuth>} />
                    </Route>
                    <Route path={"scanner"}>
                        <Route path={"order"} element={<RequireAuth><StatusScannerOrder /></RequireAuth>} />
                    </Route>
                    <Route path={"/timebank"} element={<RequireAuth><TimebankOverview /></RequireAuth>} />
                    <Route path={"/"} element={<Home />} />
                    <Route path={"*"} element={<NotFound />} />
                </Routes>
            </AuthProvider>
        </MsalProvider>
    );
}

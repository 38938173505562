import {useMsal} from "@azure/msal-react";
import {loginRequest, signUpMsalConfig} from "../../app/auth/authConfig";
import {useAuth} from "../../app/auth/AuthProvider";
import {useEffect} from "react";
import {PublicClientApplication} from "@azure/msal-browser/dist/app/PublicClientApplication";

function Home() {
    const { instance } = useMsal();
    const signUpInstance = new PublicClientApplication(signUpMsalConfig);
    const auth = useAuth();

    useEffect(() => {
        // Ensure that the state of auth.isLoggingOut is correctly set.
        // If we are on the home page, we are definitely not in the process
        // of logging out.
        if (auth)
            auth.logoutComplete();
    }, []);

    return (
        <div className={"w-full min-h-screen flex items-center align-middle"}>
            <div className="mx-auto my-0 text-center flex flex-col justify-center" style={{maxWidth: '1000px', height: '500px'}}>
                <div className={"mx-auto mb-6"} style={{width: '30%'}}>
                    <img src={"/img/navipartner-logo.svg"} alt={"NaviPartner logo"} />
                </div>
                <div>
                    <h2 className={"text-xl font-semibold text-gray-900 mb-6"}>Welcome to NaviPartner's Customer Portal</h2>
                    <p className={"w-3/4 mx-auto"}>
                        To use the system you need a user linked to your contact with NaviPartner. If you already have an account please click the login button.
                        To create an account, click the create account button. Once your account is created we will be able to give you access to the portal.
                        <br />
                        <br />
                        If you had an account in Extranet please create a new account first, then you will be able to link your existing account.
                    </p>
                    <div className={"mt-8"}>
                        <button type="button"
                                className={"mr-2 bg-blue-600 px-8 py-2 border border-blue-600 rounded text-white"}
                                onClick={() => {
                                    auth.signout();
                                    instance.loginRedirect(loginRequest);
                                }}
                        >
                            Login
                        </button>
                        <button type={"button"}
                                className={"ml-2 px-3 py-2 border border-blue-600 rounded text-blue-600"}
                                onClick={() => {
                                    auth.signout();
                                    signUpInstance.loginRedirect(loginRequest)
                                }}
                        >
                            Create account
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
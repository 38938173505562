import React, {Fragment, ReactFragment, useState} from "react";
import {Dialog, Menu, Transition} from "@headlessui/react";
import {MenuAlt2Icon, XIcon,} from '@heroicons/react/outline'
import {Link, useLocation} from "react-router-dom";
import {useAppSelector} from "../app/redux/hooks";
import {User} from "../app/models/users/User";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

type BasePageProps = {
    pageName: string
    content: ReactFragment
}

const navigation = {
    "cases-create": { name: "Create new case", href: "/cases/create", current: false, parent: "" },
    "cases": { name: "Cases", href: "/cases", current: false, parent: "" },
    "cases-awaiting-customer": { name: "Awaiting customer", href: "/cases/awaiting", current: false, parent: "cases" },
    "cases-active": { name: "Active cases", href: "/cases/active", current: false, parent: "cases" },
    "cases-completed": { name: "Completed cases", href: "/cases/completed", current: false, parent: "cases" },
    "cases-postponed": { name: "Postponed cases", href: "/cases/postponed", current: false, parent: "cases" },
    "cases-closed": { name: "Closed cases", href: "/cases/closed", current: false, parent: "cases" },
    "cases-all": { name: "All cases", href: "/cases/all", current: false, parent: "cases" },
    "timebank-entries": { name: "Timebank overview", href: "/timebank", current: false, parent: "" },
    "scanner-order": { name: "Scanner Reservation", href: "/scanner/order", current: false, parent: "" },
}

const userNavigation = [
    { name: "Log out", href: "/auth/logout" }
]

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

function hasChildren(item: any): boolean {
    return Object.values(navigation).filter((child) => child.parent.toLowerCase() === item.name.toLowerCase()).length > 0;
}

function hasActiveChild(item: any): boolean {
    return Object.values(navigation).filter((child) => child.parent.toLowerCase() === item.name.toLowerCase())
        .filter((child) => child.current).length > 0;
}

export default function BasePage(props: BasePageProps) {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const location = useLocation();
    const user: User | undefined = useAppSelector((state) => state.user.user);

    Object.values(navigation)
        .map((item) => item.current = (item.href.toLowerCase() === location.pathname.toLowerCase()));

    return(
        <div className={"h-screen h-full"}>
            <div className={"h-screen"}>
                <Transition.Root show={sidebarOpen} as={Fragment}>
                    <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={setSidebarOpen}>
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                        </Transition.Child>
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                                        <button
                                            type="button"
                                            className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                            onClick={() => setSidebarOpen(false)}
                                        >
                                            <span className="sr-only">Close sidebar</span>
                                            <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className="flex-shrink-0 flex items-center px-4">
                                    <img
                                        className="h-8 w-auto"
                                        src="/img/navipartner-logo.svg"
                                        alt="NaviPartner Logo"
                                    />
                                </div>
                                <div className="mt-5 flex-1 h-0 overflow-y-auto">
                                    <nav className="px-2 space-y-1">
                                        {Object.values(navigation).filter((item) => item.parent === "").map((item) => (
                                            <Link
                                                key={item.name}
                                                to={item.href}
                                                className={classNames(
                                                    item.current
                                                        ? 'bg-gray-100 text-gray-900'
                                                        : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                                    'group flex items-center px-2 py-3 text-base font-medium rounded-md'
                                                )}
                                            >
                                                {item.name}
                                            </Link>
                                        ))}
                                    </nav>
                                </div>
                            </div>
                        </Transition.Child>
                        <div className="flex-shrink-0 w-14" aria-hidden="true">
                            {/* Dummy element to force sidebar to shrink to fit close icon */}
                        </div>
                    </Dialog>
                </Transition.Root>

                {/* Static sidebar for desktop */}
                <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex flex-col flex-grow border-r border-gray-200 pt-4 bg-white overflow-y-auto">
                        <div className="flex items-center flex-shrink-0 px-4">
                            <img
                                className="w-100 h-auto"
                                src="/img/navipartner-logo.svg"
                                alt="NaviPartner"
                            />
                        </div>
                        <div className="mt-5 flex-grow flex flex-col">
                            <nav className="flex-1 px-2 pb-4 space-y-1">
                                {Object.values(navigation).filter((item) => item.parent === "").map((item) => (
                                    <div>
                                        <Link
                                            key={item.href}
                                            to={(hasChildren(item)) ? "#" : item.href}
                                            className={classNames(
                                                (item.current || hasActiveChild(item)) ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                                'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                                            )}
                                        >
                                            {item.name}
                                        </Link>
                                        <ul className={"relative accordian-collapse collapse " + (hasActiveChild(item)) ? "show" : ""}>
                                        {
                                            Object.values(navigation).filter((child) => child.parent.toLowerCase() === item.name.toLowerCase())
                                                .map((child) => (
                                                <li>
                                                    <Link
                                                        key={child.name}
                                                        to={child.href}
                                                        className={classNames(
                                                            child.current ? 'bg-gray-100 ' : 'hover:bg-gray-50',
                                                            'group flex items-center px-2 py-2 text-sm font-medium rounded-md ml-4',
                                                            (child.href === "/cases/awaiting") ? 'text-red-600 hover:text-red-700' :
                                                                child.current ? 'text-gray-900 hover:text-gray-900' : 'text-gray-600 hover:text-gray-900'
                                                        )}
                                                    >
                                                        {child.name}
                                                    </Link>
                                                </li>
                                            ))
                                        }
                                        </ul>
                                    </div>
                                ))}
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="md:pl-64 flex flex-col flex-1">
                    <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 border-solid">
                        <button
                            type="button"
                            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                            onClick={() => setSidebarOpen(true)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                        </button>
                        <div className="flex-1 px-4 flex justify-between">
                            <div className="ml-auto flex items-center">
                                {/* Profile dropdown */}
                                <Menu as="div" className="ml-3 relative">
                                    <div>
                                        <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                            <span className="sr-only">Open user menu</span>
                                            Welcome back, {user?.name}!
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            {userNavigation.map((item) => (
                                                <Menu.Item key={item.name}>
                                                    {({ active }) => (
                                                        <Link
                                                            to={item.href}
                                                            className={classNames(
                                                                active ? 'bg-gray-100' : '',
                                                                'block px-4 py-2 text-sm text-gray-700'
                                                            )}
                                                        >
                                                            {item.name}
                                                        </Link>
                                                    )}
                                                </Menu.Item>
                                            ))}
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                    </div>

                    <main className="flex-1">
                        <div className="py-6">
                            <div className={"mx-auto"}>
                                <div className="mx-auto px-4 sm:px-6 md:px-8">
                                    <h1 className="text-2xl font-semibold text-gray-900">{props.pageName}</h1>
                                </div>
                                <div className="mx-auto px-4 sm:px-6 md:px-8">
                                    <div className="py-2">
                                        {props.content}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                {(process.env.NODE_ENV !== "test") ? <ToastContainer /> : <></>}
            </div>
        </div>
    )
}
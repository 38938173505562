import React from "react";
import {User} from "../models/users/User";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {setUser} from "../redux/users/userSlice";
import {useMsal} from "@azure/msal-react";
import {persistor} from "../redux/store";

interface AuthContextType {
    authUser: any;
    isLoggingOut: boolean;
    signin: (newUser: User, expiresOn: Date, callback: VoidFunction) => void;
    signout: (callback?: VoidFunction, redirectUri?: string) => void;
    logoutComplete: () => void;
}

let AuthContext = React.createContext<AuthContextType>(null!);

export default function AuthProvider({ children }: { children: React.ReactNode }) {
    let [authUser, setAuthUser] = React.useState<any>(null);
    let [isLoggingOut, setIsLoggingOut] = React.useState<boolean>(false);
    const { instance } = useMsal();
    const dispatch = useAppDispatch();
    const user = useAppSelector((state => state.user.user));

    let signin = (newUser: User, expiresOn: Date, callback: VoidFunction) => {
        dispatch(setUser({user: newUser, expiry: expiresOn}));
        setAuthUser(newUser);
        return callback();
    };

    let signout = async (callback?: VoidFunction, redirectUri?: string) => {
        setIsLoggingOut(true);
        dispatch(setUser({user: undefined, expiry: undefined}));
        setAuthUser(null);
        await persistor.purge();

        if (redirectUri) {
            await instance.logout({
                postLogoutRedirectUri: redirectUri
            }).catch((e) => console.log(e));
        } else {
            await instance.logout().catch(e => console.log(e));
        }

        if (callback)
            return callback();
    };

    let logoutComplete = () => {
        setIsLoggingOut(false);
    }

    if (authUser === null && user)
        setAuthUser(user);

    let value = { authUser, isLoggingOut, signin, signout, logoutComplete };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
    return React.useContext(AuthContext);
}

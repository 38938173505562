import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../app/redux/hooks";
import CaseCard from "./CaseCard";
import NotFound from "../NotFound/NotFound";
import BasePage from "../../layout/BasePage";
import {useEffect, useState} from "react";
import {fetchCaseByNo, resetCaseData} from "../../app/redux/cases/casesSlice";
import {fetchCaseComments} from "../../app/redux/cases/caseCommentsSlice";
import {fetchCaseFiles} from "../../app/redux/cases/caseFilesSlice";

export default function CaseCardPage() {
    let { caseNo } = useParams();
    let user = useAppSelector((state => state.user.user));
    let caseState = useAppSelector((state => state.caseState));
    let caseCommentState = useAppSelector((state => state.caseCommentState));
    let caseFileState = useAppSelector((state => state.caseFileState));
    let npCase = caseState.cases[caseNo ?? ""];
    let dispatch = useAppDispatch();

    useEffect(() => {
        if (!(caseNo && user))
            return;

        dispatch(resetCaseData());
        dispatch(fetchCaseByNo({user: user, caseNo: caseNo}));
        dispatch(fetchCaseComments({user: user, caseNo: caseNo}));
        dispatch(fetchCaseFiles({user: user, caseNo: caseNo}));
    }, [user, caseNo]);

    if (!caseState.isLoading && !npCase)
        return (
            <BasePage pageName={"Case not found"} content={<NotFound />} />
        );

    if (caseState.isLoading)
        return (
            <BasePage pageName={"Case " + caseNo} content={<div>Loading case...</div>} />
        )

    return (
        <BasePage pageName={npCase.description} content={
            <CaseCard case={npCase} commentState={caseCommentState} fileState={caseFileState} />
        } />
    );
}
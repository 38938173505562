export default class ApiHelper {

    static readonly _baseUrl: string = ((process.env.NODE_ENV !== 'test') ? process.env.REACT_APP_APIM_URL : "http://testserver") + "/portal-api/";

    static makeGetRequest(path: string, headers: any): Promise<Response> {
        let reqHeaders = {
            ...headers,
            ...this.getDefaultHeaders()
        }

        return fetch(this._baseUrl + path, {
            method: 'GET',
            headers: reqHeaders
        })
    }

    static async makePostRequest(path: string, body: string, headers: any): Promise<Response> {
        return this.makeRequestWithBody(path, 'POST', body, headers);
    }

    static async makePostRequestWithoutBody(path: string, headers: any): Promise<Response> {
        return this.makeRequest(path, 'POST', headers);
    }

    private static async makeRequest(path: string, method: string, headers: any): Promise<Response> {
        return fetch(this._baseUrl + path, {
            'method': method,
            'headers': {
                ...headers,
                ...this.getDefaultHeaders()
            }
        });
    }

    private static async makeRequestWithBody(path: string, method: string, body: string, headers: any): Promise<Response> {
        return fetch(this._baseUrl + path, {
            'method': method,
            'headers': {
                ...headers,
                ...this.getDefaultHeaders()
            },
            'body': body,
        });
    }

    static async makePatchRequest(path: string, body: string, headers: any): Promise<Response> {
        return this.makeRequestWithBody(path, 'PATCH', body, headers);
    }

    static apiError(res: Response) {
        throw new Error('Error while calling the API\n' + res.status + ' - ' + res.statusText);
    }

    static getDefaultHeaders() {
        const apimKey: string = process.env.REACT_APP_APIM_KEY ?? "";

        return {
            'Ocp-Apim-Subscription-Key': apimKey,
            'Api-Version': 'v1'
        }
    }
}

import {CaseCreateRequest} from "../models/cases/CaseCreateRequest";

export default class CaseHelper {

    /**
     * Check that the given case object is valid.
     * @param caseRequest
     */
    static caseIsValid(caseRequest: CaseCreateRequest): boolean {
        if (!caseRequest.description || caseRequest.description.length > 50)
            return false;

        if (!caseRequest.contactName || caseRequest.contactName.length > 30)
            return false;

        if (!caseRequest.contactEmail ||
            !caseRequest.contactEmail.includes('@') ||
            caseRequest.contactEmail.length > 80
        )
            return false;

        if (!caseRequest.contactPhone || caseRequest.contactPhone.length > 30)
            return false;

        if (!caseRequest.comment)
            return false;

        if (caseRequest.externalDocNo && caseRequest.externalDocNo.length > 20)
            return false;

        return true;
    }
}
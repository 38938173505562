import {CaseComment} from "../../models/cases/CaseComment";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {User} from "../../models/users/User";
import ApiHelper from "../../helpers/ApiHelper";
import {toast} from "react-toastify";

export interface CaseCommentState {
    isLoading: boolean;
    addingComment: boolean;
    caseComments: {
        [lineNumber: string]: CaseComment
    }
}

const initialState: CaseCommentState = {isLoading: false, addingComment: false, caseComments: {}};

const caseCommentsSlice = createSlice({
    name: 'caseComments',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase('caseComments/fetchCaseComments/pending', (state) => {
                state.isLoading = true;
                state.caseComments = {};
            })
            .addCase('caseComments/fetchCaseComments/fulfilled', (state, action: any) => {
                state.isLoading = false;

                if (!action.payload) {
                    return;
                }

                action.payload.caseComments.forEach((comment: any) => {
                    comment.date = new Date(comment.date);
                    state.caseComments[comment.Linienr] = comment as CaseComment;
                });
            })
            .addCase('caseComments/addComment/pending', (state) => {
                state.addingComment = true;
            })
            .addCase('caseComments/addComment/fulfilled', (state, action: any) => {
                state.addingComment = false;

                if (!action.payload) {
                    return;
                }

                action.payload.comments.forEach((comment: any) => {
                    comment.date = new Date(comment.date);
                    state.caseComments[comment.Linienr] = comment as CaseComment;
                })
            });
    }
});

export const fetchCaseComments = createAsyncThunk<{ caseComments: { [lineNumber: string]: CaseComment } } | undefined, { user: User, caseNo: string }>('caseComments/fetchCaseComments', async (input) => {
    if (!input.caseNo || input.caseNo === "")
        return undefined;

    if (!input.user)
        return undefined;

    let url = 'cases/' + input.caseNo + '/comments/';

    let response: any;
    try {
        response = await ApiHelper.makeGetRequest(url, {
            'Authorization': 'Bearer ' + input.user.accessToken,
            'Accept': 'application/json'
        });

        if (!response.ok) {
            ApiHelper.apiError(response);
        }

        response = await response.json();
    } catch (e) {
        console.error(e);
        toast.error("An error occurred while fetching case comments. Please try again in a while");
        return;
    }

    return { caseComments: response };
});

export const addComment = createAsyncThunk<{ comments: { [lineNumber: string]: CaseComment } } | undefined, { user: User, caseNo: string, comment: string }>('caseComments/addComment', async (input) => {
    if (!input.caseNo || input.caseNo === "")
        return;

    if (!input.user)
        return;

    if (!input.comment || input.comment === "")
        return;

    let comment = input.comment.trimEnd();

    let body = {
        comment: comment
    };

    let response: any;
    try {
        response = await ApiHelper.makePostRequest('cases/' + input.caseNo + '/comments/', JSON.stringify(body), {
            'Authorization': 'Bearer ' + input.user.accessToken,
            'Content-Type': 'application/json'
        });

        if (!response.ok) {
            ApiHelper.apiError(response);
        }

        response = await response.json();
    } catch (e) {
        console.error(e);
        toast.error("An error occurred while adding your comment. Please try again in a while");
        return;
    }

    return { comments: response.comments };
});

export default caseCommentsSlice.reducer;
const azureB2CAuthorityDomain: string = process.env.REACT_APP_AZURE_B2C_AUTHORITY_DOMAIN ?? "";
const azureTenantId: string = process.env.REACT_APP_AZURE_TENANT_ID ?? "";
const signInWorkflow: string = process.env.REACT_APP_AZURE_B2C_SIGN_IN_WORKFLOW ?? "";
const signUpWorkflow: string = process.env.REACT_APP_AZURE_B2C_SIGN_UP_WORKFLOW ?? "";
const pwdResetWorkflow: string = process.env.REACT_APP_AZURE_B2C_PWD_RESET_WORKFLOW ?? "";
const azureB2CClientId: string = process.env.REACT_APP_AZURE_B2C_CLIENT_ID ?? "";

export const b2cPolicies = {
    names: {
        signUpSignIn:  signInWorkflow,
        signUp: signUpWorkflow,
        pwdReset: pwdResetWorkflow
    },
    authorities: {
        signUpSignIn: {
            authority: "https://" + azureB2CAuthorityDomain + "/" + azureTenantId + "/" + signInWorkflow
        },
        signUp: {
            authority: "https://" + azureB2CAuthorityDomain + "/" + azureTenantId + "/" + signUpWorkflow
        },
        pwdReset: {
            authority: "https://" + azureB2CAuthorityDomain + "/" + azureTenantId + "/" + pwdResetWorkflow
        }
    },
    authorityDomain: azureB2CAuthorityDomain
}


export const msalConfig = {
    auth: {
        clientId: azureB2CClientId,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: process.env.REACT_APP_B2C_RETURN_URL,
        postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false
    }
}

export const signUpMsalConfig = {
    auth: {
        clientId: azureB2CClientId,
        authority: b2cPolicies.authorities.signUp.authority,
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: process.env.REACT_APP_B2C_RETURN_URL,
        postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false
    }
}

export const resetPwdConfig = {
    auth: {
        clientId: azureB2CClientId,
        authority: b2cPolicies.authorities.pwdReset.authority,
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: process.env.REACT_APP_B2C_RETURN_URL,
        postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false
    }
}

// By default, openid, profile, and email are added to any login request
export const loginRequest = {
    scopes: []
};
import {useAppSelector} from "../../app/redux/hooks";
import BasePage from "../../layout/BasePage";
import {FormEvent, useState} from "react";
import ApiHelper from "../../app/helpers/ApiHelper";
import {toast} from "react-toastify";

export default function StatusScannerOrder() {
    return(
        <BasePage pageName={"Reserve Status Scanners"} content={
            <StatusScannerOrderForm />
        } />
    );
}

function StatusScannerOrderForm() {
    const user = useAppSelector((state => state.user.user));
    const [contactName, setContactName] = useState("");
    const [contactPhone, setContactPhone] = useState("");
    const [contactEmail, setContactEmail] = useState("");
    const [address, setAddress] = useState("");
    const [address2, setAddress2] = useState("");
    const [postCode, setPostCode] = useState("");
    const [city, setCity] = useState("");
    const [requestedDeliveryDate, setRequestedDeliveryDate] = useState<Date>();
    const [statusStart, setStatusStart] = useState<Date>();
    const [statusEnd, setStatusEnd] = useState<Date>();
    const [qty, setQty] = useState<number>(0);
    const [comment, setComment] = useState("");

    const makeReservation = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!user)
            return;

        if (!statusEnd || !statusStart)
            return;

        let noOfDays = Math.floor(statusEnd.getTime() - statusStart.getTime()) / (1000 * 60 * 60 * 24) + 1; // +1 because we otherwise only get the diff. We need both days included.

        let body = {
            requestedDeliveryDate: requestedDeliveryDate,
            noOfDays: noOfDays,
            qtyOfScanners: qty,
            comments: comment,
            deliveryAddress: address,
            deliveryAddress2: address2,
            deliveryPostCode: postCode,
            deliveryCity: city,
            statusStart: statusStart,
            statusEnd: statusEnd,
            contactName: contactName,
            contactEmail: contactEmail,
            contactPhone: contactPhone
        };

        let response: any;
        try {
            response = await ApiHelper.makePostRequest('/status-scanner', JSON.stringify(body), {
                'Authorization': 'Bearer ' + user.accessToken,
                'Content-Type': 'application/json'
            });

            if (!response.ok) {
                ApiHelper.apiError(response);
            }
        } catch (e) {
            console.error(e);
            toast.error('An error occurred while submitting your request. Please try again in a while.');
            return;
        }

        toast.success("Successfully submitted request!");
        resetState();
    };

    const resetState = () => {
        setContactName("");
        setContactPhone("");
        setContactEmail("");
        setAddress("");
        setAddress2("");
        setPostCode("");
        setCity("");
        setRequestedDeliveryDate(new Date());
        setStatusStart(new Date());
        setStatusEnd(new Date());
        setQty(0);
        setComment("");
    }

    return(
        <div className={"w-3/4"}>
            <form className="space-y-2" onSubmit={(e) => makeReservation(e)}>
                <div className={"space-y-2 mb-4"}>
                    Enter your request for scanner reservation here.
                    Note that the price per scanner is 849 DKK per week per scanner.
                </div>
                <div className={"flex"}>
                    <div className={"w-1/2 mr-4"}>
                        <div className={"space-y-2 divide-gray-200"}>
                            <div>
                                <h3 className="text-lg leading-6 font-medium text-gray-900">Reservation</h3>
                                <p className="mt-1 text-sm text-gray-500">
                                    Please enter the relevant information about your request.
                                </p>
                            </div>
                            <div>
                                <label htmlFor="requestedDeliveryDate" className="block text-sm font-medium text-gray-700">
                                    Requested Delivery Date
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="date"
                                        name="requestedDeliveryDate"
                                        id="requestedDeliveryDate"
                                        className="focus:ring-indigo-500 focus:border-indigo-500 outline-none block w-full sm:text-sm border border-gray-200 rounded-md p-2"
                                        onChange={(e) => setRequestedDeliveryDate(new Date(e.currentTarget.value))}
                                        required={true}
                                    />
                                </div>
                                <p className="mt-2 text-sm text-gray-500" id="email-description">
                                    Please choose a date at least 5 days in the future, so we have adequate time to pack and ship the scanners.
                                </p>
                            </div>
                            <div>
                                <label htmlFor="qtyOfScanners" className="block text-sm font-medium text-gray-700">
                                    Quantity of Scanners
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="number"
                                        name="qtyOfScanners"
                                        id="qtyOfScanners"
                                        className="focus:ring-indigo-500 focus:border-indigo-500 outline-none block w-full sm:text-sm border border-gray-200 rounded-md p-2"
                                        onChange={(e) => setQty(+e.currentTarget.value)}
                                        required={true}
                                        placeholder={"1"}
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="statusStart" className="block text-sm font-medium text-gray-700">
                                    Status Start Date
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="date"
                                        name="statusStart"
                                        id="statusStart"
                                        className="focus:ring-indigo-500 focus:border-indigo-500 outline-none block w-full sm:text-sm border border-gray-200 rounded-md p-2"
                                        onChange={(e) => setStatusStart(new Date(e.currentTarget.value))}
                                        required={true}
                                        placeholder={"01-01-2022"}
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="statusEnd" className="block text-sm font-medium text-gray-700">
                                    Status End Date
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="date"
                                        name="statusEnd"
                                        id="statusEnd"
                                        className="focus:ring-indigo-500 focus:border-indigo-500 outline-none block w-full sm:text-sm border border-gray-200 rounded-md p-2"
                                        onChange={(e) => setStatusEnd(new Date(e.currentTarget.value))}
                                        required={true}
                                        placeholder={"01-01-2022"}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={"space-y-2 mt-4"}>
                            <div>
                                <h3 className="text-lg leading-6 font-medium text-gray-900">Contact Information</h3>
                                <p className="mt-1 text-sm text-gray-500">
                                    Please enter your contact information in case we have any issues with the reservation.
                                </p>
                            </div>
                            <div>
                                <label htmlFor="contactName" className="block text-sm font-medium text-gray-700">
                                    Contact Name
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        name="contactName"
                                        id="contactName"
                                        className="focus:ring-indigo-500 focus:border-indigo-500 outline-none block w-full sm:text-sm border border-gray-200 rounded-md p-2"
                                        onChange={(e) => setContactName(e.currentTarget.value)}
                                        required={true}
                                        maxLength={30}
                                        placeholder={"John Doe"}
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="contactEmail" className="block text-sm font-medium text-gray-700">
                                    Contact E-mail
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="email"
                                        name="contactEmail"
                                        id="contactEmail"
                                        className="focus:ring-indigo-500 focus:border-indigo-500 outline-none block w-full sm:text-sm border border-gray-200 rounded-md p-2"
                                        onChange={(e) => setContactEmail(e.currentTarget.value)}
                                        required={true}
                                        maxLength={50}
                                        placeholder={"example@example.com"}
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="contactPhone" className="block text-sm font-medium text-gray-700">
                                    Contact Phone
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="tel"
                                        name="contactPhone"
                                        id="contactPhone"
                                        className="focus:ring-indigo-500 focus:border-indigo-500 outline-none block w-full sm:text-sm border border-gray-200 rounded-md p-2"
                                        onChange={(e) => setContactPhone(e.currentTarget.value)}
                                        required={true}
                                        maxLength={20}
                                        placeholder={"+45 12345678"}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={"w-1/2 ml-2"}>

                        <div className={"space-y-2"}>
                            <div>
                                <h3 className="text-lg leading-6 font-medium text-gray-900">Delivery Address Information</h3>
                                <p className="mt-1 text-sm text-gray-500">
                                    Please enter the address at which we should ship the scanners to.
                                </p>
                            </div>
                            <div>
                                <label htmlFor="deliveryAddress" className="block text-sm font-medium text-gray-700">
                                    Delivery Address
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        name="deliveryAddress"
                                        id="deliveryAddress"
                                        className="focus:ring-indigo-500 focus:border-indigo-500 outline-none block w-full sm:text-sm border border-gray-200 rounded-md p-2"
                                        onChange={(e) => setAddress(e.currentTarget.value)}
                                        required={true}
                                        maxLength={30}
                                        placeholder={"Titangade 16"}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className={"flex justify-between"}>
                                    <label htmlFor="deliveryAddress2" className="block text-sm font-medium text-gray-700">
                                        Delivery Address 2
                                    </label>
                                    <span className="text-sm text-gray-500">
                                    Optional
                                </span>
                                </div>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        name="deliveryAddress2"
                                        id="deliveryAddress2"
                                        className="focus:ring-indigo-500 focus:border-indigo-500 outline-none block w-full sm:text-sm border border-gray-200 rounded-md p-2"
                                        onChange={(e) => setAddress2(e.currentTarget.value)}
                                        required={false}
                                        maxLength={30}
                                        placeholder={""}
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="deliveryPostCode" className="block text-sm font-medium text-gray-700">
                                    Delivery Post Code
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        name="deliveryPostCode"
                                        id="deliveryPostCode"
                                        className="focus:ring-indigo-500 focus:border-indigo-500 outline-none block w-full sm:text-sm border border-gray-200 rounded-md p-2"
                                        onChange={(e) => setPostCode(e.currentTarget.value)}
                                        required={true}
                                        maxLength={20}
                                        placeholder={"2200"}
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="deliveryCity" className="block text-sm font-medium text-gray-700">
                                    Delivery City
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        name="deliveryCity"
                                        id="deliveryCity"
                                        className="focus:ring-indigo-500 focus:border-indigo-500 outline-none block w-full sm:text-sm border border-gray-200 rounded-md p-2"
                                        onChange={(e) => setCity(e.currentTarget.value)}
                                        required={true}
                                        maxLength={30}
                                        placeholder={"København N"}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={"space-y-2 mt-4"}>
                            <div>
                                <h3 className="text-lg leading-6 font-medium text-gray-900">Order Comments</h3>
                                <p className="mt-1 text-sm text-gray-500">
                                    Enter any information not included in the other fields, you think is relevant.
                                </p>
                            </div>
                            <div className={"flex justify-between"}>
                                <label htmlFor="comment" className="block text-sm font-medium text-gray-700">
                                    Comment
                                </label>
                                <span className="text-sm text-gray-500">
                                    Optional
                                </span>
                            </div>
                            <div className="mt-1">
                                <textarea
                                    rows={4}
                                    name="comment"
                                    id="comment"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 rounded-md p-2 block w-full sm:text-sm outline-none border border-gray-200"
                                    defaultValue={''}
                                    onChange={(e) => {setComment(e.currentTarget.value)}}
                                    maxLength={250}
                                    required={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pt-4">
                    <div className="flex">
                        <button
                            type="submit"
                            className="bg-blue-600 px-3 py-2 border border-blue-600 rounded text-white"
                        >
                            Request scanners
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}
import BasePage from "../../layout/BasePage";
import {useAppSelector} from "../../app/redux/hooks";
import {User} from "../../app/models/users/User";
import {useEffect, useState} from "react";
import ApiHelper from "../../app/helpers/ApiHelper";
import {useNavigate} from "react-router-dom";
import StringUtils from "../../app/helpers/StringUtils";
import {toast} from "react-toastify";

export default function TimebankOverview() {
    let user: User | undefined = useAppSelector((state => state.user.user));
    const [entries, setEntries] = useState<Array<TimebankEntry>>();
    const [skipToken, setSkipToken] = useState<string>("");
    const [fullyLoaded, setFullyLoaded] = useState<Boolean>(false);
    const [isLoading, setIsLoading] = useState<Boolean>(false);

    useEffect(() => {
        getEntries();
    }, [user]);

    const toastApiError = () => {
        toast.error('An error occurred while getting your timebank entries');
    }

    const getEntries = async () => {
        if (!user)
            return;

        await getNextEntries();
    };

    const getNextEntries = async () => {
        if (!user)
            return;

        setIsLoading(true);

        let url = 'timebank-entries?top=101';

        if (skipToken && skipToken !== "")
            url = url + "&skiptoken=" + skipToken;

        let response: any;
        try {
             response = await ApiHelper.makeGetRequest(url, {
                'Authorization': 'Bearer ' + user.accessToken,
                'Accept': 'application/json'
            });

             if (!response.ok) {
                 ApiHelper.apiError(response);
             }

             response = await response.json();
        } catch (e) {
            console.error(e);
            toastApiError();
            return;
        }

        if (!response) {
            console.error('response object was null or undefined');
            toastApiError();
            return;
        }

        let lastEntry: TimebankEntry;
        // If we only have one entry then set that as the skiptoken.
        // Otherwise take the second to last.
        if (response.entries.length <= 1)
            lastEntry = response.entries[response.entries.length - 1];
        else
            lastEntry = response.entries[response.entries.length - 2];

        if (lastEntry) {
            const skip = "'" + lastEntry.Debitornr + "','" + lastEntry.Linienr + "','Programmering'";
            setSkipToken(skip);
        }

        setFullyLoaded(response.entries.length < 101);

        if (response.entries) {
            response.entries.forEach((entry: TimebankEntry) => {
                entry.postingDate = new Date(entry.postingDate);
            });
        }

        if (entries === undefined || entries.length === 0) {
            setEntries(response.entries.slice(0, 99));
        } else {
            setEntries(entries.concat(response.entries.slice(0, 99)));
        }

        setIsLoading(false);
    };

    if (!user) {
        return(
            <p>No user set!</p>
        );
    }

    return(
        <BasePage pageName={"Timebank Overview"} content={<TimebankOverviewComponent entries={entries ?? []} fullyLoaded={fullyLoaded} nextFunction={getNextEntries} isLoading={isLoading} />} />
    );
}

type TimebankEntry = {
    Debitornr: string,
    Linienr: string,
    qty: number,
    description: string,
    caseNo: string,
    postingDate: Date,
    externalDocNo: string
}

type OverviewComponentProps = {
    entries: Array<TimebankEntry>,
    fullyLoaded: Boolean,
    nextFunction: Function,
    isLoading: Boolean
};

function TimebankOverviewComponent(props: OverviewComponentProps) {
    const navigate = useNavigate();

    return(
        <div>
            <div className="flex flex-col w-1/2">
                <div className="my-2 overflow-x-auto">
                    <div className="align-middle inline-block min-w-full">
                        <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-6 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Case No.
                                    </th>
                                    <th scope="col" className="px-6 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Description
                                    </th>
                                    <th scope="col" className="px-6 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Posting Date
                                    </th>
                                    <th scope="col" className="px-6 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Amount
                                    </th>
                                    <th scope="col" className="px-6 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        External Doc. No.
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {props.entries.map((entry, caseIndex) => (
                                    <tr key={entry.Linienr}
                                        className={(caseIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50') + " cursor-pointer"}
                                        onClick={() => navigate("/case/" + entry.caseNo)}
                                    >
                                        <td className="px-6 py-6 whitespace-nowrap text-sm font-medium text-gray-900">{entry.caseNo}</td>
                                        <td className="px-6 py-6 whitespace-nowrap text-sm text-gray-500">{entry.description}</td>
                                        <td className="px-6 py-6 whitespace-nowrap text-sm text-gray-500">{StringUtils.getDateFormat(entry.postingDate)}</td>
                                        <td className="px-6 py-6 whitespace-nowrap text-sm text-gray-500">{entry.qty}</td>
                                        <td className="px-6 py-6 whitespace-nowrap text-sm text-gray-500">{entry.externalDocNo}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={"mt-4 flex justify-center"}>
                        {
                            (!props.fullyLoaded)
                                ?   <button type={"button"}
                                            onClick={() => props.nextFunction()}
                                            className={"bg-blue-600 px-3 py-2 border border-blue-600 rounded text-white " + (props.isLoading ? "invisible" : "")}
                                >
                                    Load more
                                </button>
                                : (<></>)
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
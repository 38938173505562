import {useEffect} from "react";
import {PublicClientApplication} from "@azure/msal-browser/dist/app/PublicClientApplication";
import {loginRequest, resetPwdConfig} from "./authConfig";
import {useAuth} from "./AuthProvider";

function PasswordReset() {
    const instance = new PublicClientApplication(resetPwdConfig);
    const auth = useAuth();

    useEffect(() => {
        if (auth.authUser) {
            // If the user is already logged in with an account, we need to ensure that they are logged out first
            // before they are being redirected to reset their password.
            // We just send them back to the same location as they are already on the right page.
            auth.signout(undefined, window.location.href);
            return;
        }

        instance.loginRedirect(loginRequest);
    }, []);

    return <></>;
}

export default PasswordReset;
import {useEffect, useState} from "react";
import {useAppSelector} from "../../app/redux/hooks";
import {User} from "../../app/models/users/User";
import ApiHelper from "../../app/helpers/ApiHelper";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

export default function UserLoadingPage() {
    const user: User | undefined = useAppSelector((state => state.user.user));
    const [isLoading, setIsLoading] = useState(true);
    const [isMapped, setIsMapped] = useState<boolean | undefined>(undefined);
    const navigate = useNavigate();

    const translateTokenToContactNo = async () => {
        if (user === undefined)
            return;

        let mapped = await userIsMapped();
        setIsMapped(mapped);
        if (typeof mapped == "boolean" && mapped) {
            navigate("/cases");
        } else if (mapped === undefined) {
            await registerAccount();
        }

        setIsLoading(false);
    }

    const registerAccount = async () => {
        if (user === undefined)
            return;

        const body = {
            userID: user.b2cAccountId
        };

        let response: any;
        try {
            response = await ApiHelper.makePostRequest('contacts/mapping', JSON.stringify(body), {
                'Authorization': 'Bearer ' + user.accessToken,
                'Content-Type': 'application/json'
            });

            if (!response.ok) {
                ApiHelper.apiError(response);
            }
        } catch (e) {
            console.error(e);
            toast.error("An error occurred while registering your account. Please wait a while and log out and back in to reattempt registration");
            return;
        }

        setIsLoading(false);
        setIsMapped(false);
    }

    const userIsMapped = async (): Promise<boolean | undefined> => {
        if (user === undefined)
            return undefined;

        let response: any;
        try {
            response = await ApiHelper.makeGetRequest('contacts/mapping/' + user.b2cAccountId, {
                'Authorization': 'Bearer ' + user.accessToken,
                'Accept': 'application/json'
            });

            if (response.status === 404)
                return undefined;

            if (!response.ok) {
                ApiHelper.apiError(response);
            }

            response = await response.json();
        } catch (e) {
            console.error(e);
            toast.error("An error occurred while trying to get your user information. Please wait a while and try again");
            return;
        }

        const contactNo = response["contactNo"];
        return Boolean(contactNo && (contactNo !== ""));
    }

    const redirectLogout = () => {
        navigate("/auth/logout");
    };

    useEffect(() => {
        translateTokenToContactNo();
    }, [user]);

    return (
        <div className={"min-h-screen min-w-full flex items-center"}>
            <div className={"mx-auto my-0 flex flex-col"}>
                <div className={"text-center"}>
                {
                    (isLoading)
                        ? <p>Loading current user information...</p>
                        : (isMapped === false)
                            ?   <div>
                                    <p>We didn't recognize your account. Contact support to have a contact assigned.<br />
                                        <span className={"text-center"}>Your ID: {user?.b2cAccountId}</span>
                                    </p>
                                    <p className={"mt-4"}>Have a previous Extranet account? <button type={"button"} onClick={() => navigate('/auth/previousAccount')}>Link it here!</button></p>
                                </div>
                            : <></>

                }
                </div>
                <div className={"mx-auto mt-4"}>
                    <button type={"button"} className={"border border-blue-600 px-4 py-2 rounded text-blue-600 mr-4"} onClick={() => redirectLogout()}>Log out</button>
                    {
                        (isMapped === true)
                            ? <button type={"button"} className={"bg-blue-600 px-4 py-2 border border-blue-600 rounded text-white"} onClick={() => navigate("/cases")}>Cases</button>
                            : <></>
                    }
                </div>
            </div>
        </div>
    );
}
import {useAppDispatch, useAppSelector} from "../../app/redux/hooks";
import CaseList from "./CaseList";
import {useParams} from "react-router-dom";
import CaseStatus from "../../app/models/cases/CaseStatus";
import BasePage from "../../layout/BasePage";
import StringUtils from "../../app/helpers/StringUtils";
import {CaseState, fetchNextCases, resetCaseData} from "../../app/redux/cases/casesSlice";
import {useEffect} from "react";

function CaseListPage() {
    let { status } = useParams();
    let caseState: CaseState = useAppSelector(state => state.caseState);
    let user = useAppSelector(state => state.user.user);
    let dispatch = useAppDispatch();

    let caseStatus = CaseStatus[status as keyof typeof CaseStatus];

    const loadMore = () => {
        if (user)
            dispatch(fetchNextCases({user: user, skipToken: caseState.skipToken, status: status ?? ""}))
    }

    useEffect(() => {
        dispatch(resetCaseData());

        if (user)
            dispatch(fetchNextCases({user: user, skipToken: "", status: status ?? ""}));
    }, [user, caseStatus]);

    let content = (caseStatus === undefined && status !== "all")
                    ? <p>Unknown case status</p>
                    : (status === "all")
                        ?   <CaseList cases={Object.values(caseState.cases)}
                                    fullyLoaded={caseState.fullyLoaded}
                                    isLoading={caseState.isLoading}
                                    nextFunction={loadMore}
                            />

                        :   <CaseList cases={Object.values(caseState.cases).filter((item) => item.status === caseStatus)}
                                    fullyLoaded={caseState.fullyLoaded}
                                    isLoading={caseState.isLoading}
                                    nextFunction={loadMore}
                            />

    return (
        <BasePage
            pageName={StringUtils.capitalize(status ? status : "") + " cases"}
            content={content}
        />
    );
}

export default CaseListPage;
import {Case} from "../../models/cases/Case";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import ApiHelper from "../../helpers/ApiHelper";
import {User} from "../../models/users/User";
import CaseStatus from "../../models/cases/CaseStatus";
import {CaseComment} from "../../models/cases/CaseComment";
import {toast} from "react-toastify";

export interface CaseState {
    fullyLoaded: boolean;
    skipToken: string;
    isLoading: boolean;
    selectedCase: string;
    cases: {
        [caseNo: string]: Case
    }
    caseComments: {
        [lineNumber: string]: CaseComment
    }
}

const initialState: CaseState = {fullyLoaded: false, skipToken: "", isLoading: false, selectedCase: "", cases: {}, caseComments: {}};

const casesSlice = createSlice({
    name: 'cases',
    initialState,
    reducers: {
        resetCaseData(state) {
            state.fullyLoaded = false;
            state.cases = {};
            state.skipToken = "";
            state.selectedCase = "";
            state.caseComments = {};
        },
        setSelectedCase(state, action: PayloadAction<string>) {
            state.selectedCase = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase('cases/fetchCases/pending', (state) => {
                state.isLoading = true;
            })
            .addCase('cases/fetchCases/fulfilled', (state, action: any) => {
                if (!action.payload)
                    return;

                action.payload.cases.forEach((item: any) => {
                    delete item.filterStatus;
                    item.status = CaseStatus[item.status as keyof typeof CaseStatus];
                    item.creationDate = new Date(item.creationDate);
                    item.lastModifiedDate = new Date(item.lastModifiedDate);
                    item.expectedDeliveryDate = new Date(item.expectedDeliveryDate);
                    state.cases[item.No] = item as Case;
                });

                state.isLoading = false;
                state.fullyLoaded = action.payload.fullyLoaded;
                state.skipToken = action.payload.skipToken;
            })
            .addCase('cases/fetchCaseByNo/pending', (state) => {
                state.isLoading = true;
            })
            .addCase('cases/fetchCaseByNo/fulfilled', (state, action: any) => {
                state.isLoading = false;

                if (!action.payload)
                    return;

                let item = action.payload;

                delete item.filterStatus;
                action.payload.status = CaseStatus[item.status as keyof typeof CaseStatus];
                item.creationDate = new Date(item.creationDate);
                item.lastModifiedDate = new Date(item.lastModifiedDate);
                item.expectedDeliveryDate = new Date(item.expectedDeliveryDate);
                state.cases[item.No] = item as Case;
            })
    }
});

export const fetchNextCases = createAsyncThunk<{fullyLoaded: boolean, skipToken: string, cases: { [caseNo: string]: Case }} | undefined, {user: User, skipToken: string, status: string}>('cases/fetchCases', async (input) => {
    let url = 'cases?top=301';
    if (input.skipToken !== "")
        url = url + "&skiptoken=" + input.skipToken;

    if (input.status !== "" && input.status !== "all")
        url = url + "&status=" + input.status;

    let response: any;
    try {
        response = await ApiHelper.makeGetRequest(url, {
            'Authorization': 'Bearer ' + input.user.accessToken,
            'Accept': 'application/json'
        });

        if (!response.ok) {
            ApiHelper.apiError(response);
        }

        response = await response.json();
    } catch (e) {
        console.error(e);
        toast.error("An error occurred while getting the cases. Please try again in a while.");
        return;
    }

    let fullyLoaded = (response.cases.length < 301);
    let skipToken = response.cases[299] ? response.cases[299].No : "";

    return {fullyLoaded: fullyLoaded, skipToken: skipToken, cases: response.cases.slice(0, 299)};
});

export const fetchCaseByNo = createAsyncThunk<Case | undefined, {user: User, caseNo: string}>('cases/fetchCaseByNo', async (input) => {
   let url = 'cases/' + input.caseNo;

   let response: any;
   try {
       response = await ApiHelper.makeGetRequest(url, {
           'Authorization': 'Bearer ' + input.user.accessToken,
           'Accept': 'application/json'
       });

       if (!response.ok) {
           ApiHelper.apiError(response);
       }

       response = await response.json();
   } catch (e) {
       console.error(e);
       toast.error("An error occurred while getting the case. Please try again in a while");
       return;
   }

   return response;
});



export const { resetCaseData, setSelectedCase } = casesSlice.actions;

export default casesSlice.reducer;
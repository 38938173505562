import {Navigate} from "react-router-dom";
import {useAuth} from "./AuthProvider";

export default function RequireAuth({ children }: { children: JSX.Element}) {
    let auth = useAuth();

    return auth.authUser
        ? children
        : !auth.isLoggingOut
            // If we are in the process of logging out we do not need
            // to redirect to front page since Azure B2C will take
            // care of that.
            ? <Navigate to={"/"} replace />
            : <></>
}
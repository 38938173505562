import {User} from "../../models/users/User";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface UserState {
    user: User | undefined;
    expiry: Date | undefined;
}

const initialState: UserState = { user: undefined, expiry: undefined };

const userSlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        setUser(state, action: PayloadAction<{user: User | undefined, expiry: Date | undefined }>) {
            return { ...state, user: action.payload.user, expiry: action.payload.expiry }
        }
    }
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;